@font-face {
  font-family: 'Campton';
  src: url('../assets/fonts/Campton-Medium.woff2') format('woff2'),
    url('../assets/fonts/Campton-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton';
  src: url('../assets/fonts/Campton-Bold.woff2') format('woff2'),
    url('../assets/fonts/Campton-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton';
  src: url('../assets/fonts/Campton-Light.woff2') format('woff2'),
    url('../assets/fonts/Campton-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnat Poster';
  src: url('../assets/fonts/Magnat-PosterLight.woff2') format('woff2'),
    url('../assets/fonts/Magnat-PosterLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnat Poster';
  src: url('../assets/fonts/Magnat-PosterBold.woff2') format('woff2'),
    url('../assets/fonts/Magnat-PosterBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnat Poster';
  src: url('../assets/fonts/Magnat-PosterMedium.woff2') format('woff2'),
    url('../assets/fonts/Magnat-PosterMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
